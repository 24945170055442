import React, { useState } from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Section from "../../components/template-partials/global-components/section"
// import ShareButtons from "../../components/template-partials/global-components/share-buttons"
import InteriorMenu from "../../components/template-partials/global-components/interior-menu"
import InteriorPageHeader from "../../components/template-partials/global-components/interior-page-header"
// import InteriorPageFooter from "../../components/template-partials/global-components/interior-page-footer"
//import QuestionBuilder from "../../components/template-partials/resources-and-support/question-builder"
import ResourcesAndSupportSubmenu from "../../components/template-partials/resources-and-support/resources-and-support-submenu"
import AddToOrderButton from "../../components/template-partials/global-components/addtoorderbutton"

// import {
//   UpNextCard,
//   ExploreCard,
// } from "../../components/template-partials/global-components/footer-cards"

import headingBg from "../../images/7.0-headerbg.jpg"
import headingRound from "../../images/resources-and-support-nav-image.jpg"
import thumbStarterKit from "../../images/thumbStarter-Kit.png"
import thumbCopingKit from "../../images/thumbCoping-Kit.png"
import bigAdventures from "../../images/7.4_the-big-adventures-of-little-skivolo.jpg"
import nextBigAdventures from "../../images/3.4.1_antibodybook.jpg"
import thumbCompanionPiece from "../../images/thumb-nb-companion-piece.png"
import thumbResource from "../../images/thumb-coping-with-childhood.png"
import thumbStickerRefill from "../../images/thumb-sticker-refill.png"
import thumbTreatmentJournal from "../../images/thumb-treatment-journal.png"
import bookOfFun from "../../images/7.4_little-skivolo-big-book-of-fun.jpg"
import zara from "../../images/Zaratakesoff_v23.jpg"

import {
  faCheck,
  faPrint,
  faDownload,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import axios from "axios"

const RequestResources = () => {
  const [formType, setFormType] = useState("")
  const [isError, setIsError] = useState(false)

  const max1 = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
  const max2 = [0, 1, 2, 3, 4, 5]
  const max3 = [0, 1]

  const showForm = e => {
    //console.log(document.getElementById("patient").checked)
    if (document.getElementById("patient").checked) {
      setFormType(document.getElementById("patient").value)
    }
    if (document.getElementById("hcp").checked) {
      setFormType(document.getElementById("hcp").value)
    }
  }

  const [bigAdvQty, setAdvQty] = useState(0)
  const [nextBigAdvQty, setNextBigQty] = useState(0)
  const [zaraQty, setZaraQty] = useState(0)
  const [calQty, setCalQty] = useState(0)

  const validateQty = e => {
    let el = e.target
    let selections = document.querySelectorAll(
      'select[qty-group="' + el.getAttribute("qty-group") + '"]'
    )
    let group = el.getAttribute("qty-group")

    let selectedQty = 0
    let maxLimit = 0
    let errorTextElement = ""

    selections.forEach(item => {
      maxLimit = Number(item.length) - 1
      selectedQty += Number(item.options[item.selectedIndex].value)
      item.setCustomValidity("")
      if (group === "big-adv") setAdvQty(selectedQty)
      if (group === "next-big-adv") setNextBigQty(selectedQty)
      if (group === "zara") setZaraQty(selectedQty)
      if (group === "calendar") setCalQty(selectedQty)

      let targetEl =
        item.parentElement.parentElement.parentElement.parentElement
      if (targetEl.getElementsByClassName("invalid-text") == null) {
      } else {
        errorTextElement = targetEl.getElementsByClassName("invalid-text")
      }
    })

    if (selectedQty > maxLimit) {
      setIsError(true)
      errorTextElement[0].style.display = "block"
      selections.forEach(item => {
        if (Number(item.options[item.selectedIndex].value) > 0) {
          item.setCustomValidity("invalid")
        }
      })
    } else {
      setIsError(false)
      errorTextElement[0].style.display = "none"
    }
  }

  const submitForm = e => {
    e.preventDefault()
    e.stopPropagation()

    const form = e.target
    const formURL = e.target.action
    const formData = new FormData(e.target)
    
    //Create an object from the form data entries
    let formDataObject = Object.fromEntries(formData.entries())
    // Format the plain form data as JSON
    let data = JSON.stringify(formDataObject)
    if (form.checkValidity() && !isError){
      axios.post(formURL, data,{
        "headers" : {"Accept": "application/json"}
      })
      .then(function (response) {
        //console.log(response.status);
        //console.log(data)
        window.location = "/resources/thank-you/"
      })
      .catch(function (error) {
        //console.log('Error: '+ error);
        let el = document.getElementById('error-message');
        el.innerHTML = "Server error occurred!"
        setIsError(true)
      });
    }
    else{
      console.log('invalid form')
      //custom validation
      setIsError(true)
      form.classList.add("submit-attempted");
      window.scrollTo(0, 0);
    }
  }

  return (
    <Layout>
      <SEO
        title="Request Neuroblastoma Resources"
        description="Find resources available to help support patients, families, and healthcare professionals along the neuroblastoma treatment journey."
      />

      <InteriorPageHeader
        backgroundImage={headingBg}
        roundImage={headingRound}
        alt="Request Resources"
      >
        <h1 className="section__heading section__heading--green h1">
          Request Resources
        </h1>
      </InteriorPageHeader>

      {/* style={{backgroundColor: SassVars.viridian}} */}
      <Section className="interior-page">
        <div className="row row--inner">
          {formType.includes("patient") ? (
            <div className="columns">
              <h2 className="tc-barney h2 jost-med">Request Resources</h2>
              <p>
                United Therapeutics Oncology is proud to provide a variety of
                unique resources for parents, caregivers, and families like you
                to stay informed and feel supported throughout your childhood
                cancer journey and beyond.
              </p>
              <p>
                Below, you will find the list of resources created specifically
                for families navigating the childhood cancer journey. These
                resources are available at no cost to you. Simply complete and
                submit the form below, and we'll send them directly to your
                home.
              </p>
              <p>
                Our team is dedicated to serving this brave community. We are
                committed to providing comprehensive resources to families and
                caregivers touched by this childhood cancer. We are always here
                to help support you.{" "}
              </p>
              <p>
                <strong>
                  Please fill out the form below. All fields required unless
                  otherwise noted.
                </strong>
              </p>
            </div>
          ) : formType.includes("hcp") ? (
            <div className="columns">
              <h2 className="tc-barney h2 jost-med">Request Resources</h2>
              <p>
                United Therapeutics Oncology is proud to provide a variety of
                unique resources for your pediatric neuroblastoma patients on
                their cancer journey.{" "}
              </p>
              <p>
                Below, you will find the list of resources created specifically
                for families navigating the childhood cancer journey. These
                resources are available at no cost to you. Simply complete and
                submit the form below, and we'll send them directly to you.
              </p>
              <p>
                We are honored to serve this brave community by offering
                additional support for you and for families and caregivers
                touched by childhood cancer.
              </p>
              <p>
                <strong>
                  Please fill out the form below. All fields required unless
                  otherwise noted.
                </strong>
              </p>
            </div>
          ) : (
            <>
              <div className="columns small-12 large-4">
                <InteriorMenu title={`Resources`}>
                  <ResourcesAndSupportSubmenu />
                </InteriorMenu>
              </div>
              <div className="columns">
                <h2 className="tc-barney h2 jost-med">Request Resources</h2>
                <fieldset>
                  <legend>
                    <p className="mb-1 md_mb-1">
                      From diagnosis to treatment and beyond, resources are
                      available to help support patients, families, and
                      healthcare professionals along the treatment journey. In
                      order to serve you better, please let us know who you are:
                    </p>
                  </legend>
                  <input
                    type="radio"
                    id="patient"
                    name="form-type"
                    value="patient"
                  />
                  <label htmlFor="patient">
                    <strong>Patient or Caregiver</strong>
                  </label>
                  <input type="radio" value="hcp" id="hcp" name="form-type" />
                  <label htmlFor="hcp">
                    <strong>Healthcare Professional</strong>
                  </label>
                </fieldset>
                <button
                  onClick={e => showForm(e)}
                  className="btn--secondary mt-2 md_mt-2 lg_mt-2"
                >
                  Next
                </button>
              </div>
            </>
          )}
        </div>
        <form
          action="https://hooks.zapier.com/hooks/catch/10998161/338qnns/"
          noValidate
          onSubmit={submitForm}
          name="resources-form"
          id="resources-form"
          method="post"
        >
          {isError && (
            <div className="row row--inner error-container">
              <div className="columns">
                <div className="row error-container__inner">
                  <div className="columns">
                    <p id="error-message">
                      We're sorry. We need you to fix the following fields:
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className={"row row--inner"}>
            <div className="columns small-12 medium-8">
              {(formType.includes("hcp") || formType.includes("patient")) && (
                <h3 className="h4 tc-viridian jost-med mt-2 lg_mt-2 md_mt-2 lg_mb-0 md_mb-0">
                  Contact Information
                </h3>
              )}
              {formType.includes("hcp") && (
                <fieldset>
                  <label htmlFor="title">Title</label>
                  <select name="title" id="title" required>
                    <option value="">Select your title</option>
                    <option value="Doctor">Doctor</option>
                    <option value="PA">PA</option>
                    <option value="NP">NP</option>
                    <option value="Nurse">Nurse</option>
                    <option value="Pharmacist">Pharmacist</option>
                    <option value="Social Worker">Social Worker</option>
                    <option value="Childlife Specialist">
                      Childlife Specialist
                    </option>
                    <option value="Psychologist/Psychiatrist">
                      Psychologist/Psychiatrist
                    </option>
                    <option value="Other">Other</option>
                  </select>
                  <FontAwesomeIcon icon={faChevronDown} />
                  <br />
                  <span className="invalid-text">
                    Oops! Please select your title
                  </span>
                </fieldset>
              )}
              {(formType.includes("hcp") || formType.includes("patient")) && (
                <>
                  <fieldset>
                    <label htmlFor="fname">First Name</label>
                    <input
                      defaultValue=""
                      required
                      aria-required="true"
                      type="text"
                      id="fname"
                      name="fname"
                      pattern="[A-Za-z-_ ]{1,32}"
                      title="First name"
                      className="required"
                    />
                    <span className="invalid-text">
                      Oops! Please fill in your first name
                    </span>
                  </fieldset>
                  <fieldset>
                    <label htmlFor="lname">Last Name</label>
                    <input
                      defaultValue=""
                      required
                      aria-required="true"
                      type="text"
                      id="lname"
                      name="lname"
                      pattern="[A-Za-z-_ ]{1,32}"
                      title="Last name"
                      className="required"
                    />
                    <span className="invalid-text">
                      Oops! Please fill in your last name
                    </span>
                  </fieldset>
                  {formType.includes("hcp") && (
                    <fieldset>
                      <label htmlFor="institution">Hospital/Institution</label>
                      <input
                        defaultValue=""
                        required
                        aria-required="true"
                        type="text"
                        id="institution"
                        name="institution"
                        pattern="[A-Za-z-_ ]{1,32}"
                        title="Hospital/Institution"
                        className="required"
                      />
                      <span className="invalid-text">
                        Oops! Please provide your hospital or institution
                      </span>
                    </fieldset>
                  )}
                  <fieldset>
                    <label htmlFor="Address">Address</label>
                    <input
                      defaultValue=""
                      required
                      aria-required="true"
                      type="text"
                      id="address"
                      name="address"
                      title="Address"
                      className="required"
                    />
                    <span className="invalid-text">
                      Oops! Please provide your address
                    </span>
                  </fieldset>
                  <fieldset>
                    <label htmlFor="Address2">Address 2 (optional)</label>
                    <input
                      defaultValue=""
                      type="text"
                      id="Address2"
                      name="Address2"
                      title="Address"
                      className=""
                    />
                  </fieldset>
                  <fieldset>
                    <label htmlFor="city">City</label>
                    <input
                      defaultValue=""
                      required
                      aria-required="true"
                      type="text"
                      id="city"
                      name="city"
                      pattern="[A-Za-z-_ ]{1,32}"
                      title="city"
                      className="required"
                    />
                    <span className="invalid-text">
                      Oops! Please provide your city
                    </span>
                  </fieldset>
                  <fieldset>
                    <label htmlFor="state">State</label>
                    <select id="state" name="state" required>
                      <option value="">Select a State</option>
                      <option value="AL">Alabama</option>
                      <option value="AK">Alaska</option>
                      <option value="AZ">Arizona</option>
                      <option value="AR">Arkansas</option>
                      <option value="CA">California</option>
                      <option value="CO">Colorado</option>
                      <option value="CT">Connecticut</option>
                      <option value="DE">Delaware</option>
                      <option value="DC">District Of Columbia</option>
                      <option value="FL">Florida</option>
                      <option value="GA">Georgia</option>
                      <option value="HI">Hawaii</option>
                      <option value="ID">Idaho</option>
                      <option value="IL">Illinois</option>
                      <option value="IN">Indiana</option>
                      <option value="IA">Iowa</option>
                      <option value="KS">Kansas</option>
                      <option value="KY">Kentucky</option>
                      <option value="LA">Louisiana</option>
                      <option value="ME">Maine</option>
                      <option value="MD">Maryland</option>
                      <option value="MA">Massachusetts</option>
                      <option value="MI">Michigan</option>
                      <option value="MN">Minnesota</option>
                      <option value="MS">Mississippi</option>
                      <option value="MO">Missouri</option>
                      <option value="MT">Montana</option>
                      <option value="NE">Nebraska</option>
                      <option value="NV">Nevada</option>
                      <option value="NH">New Hampshire</option>
                      <option value="NJ">New Jersey</option>
                      <option value="NM">New Mexico</option>
                      <option value="NY">New York</option>
                      <option value="NC">North Carolina</option>
                      <option value="ND">North Dakota</option>
                      <option value="OH">Ohio</option>
                      <option value="OK">Oklahoma</option>
                      <option value="OR">Oregon</option>
                      <option value="PA">Pennsylvania</option>
                      <option value="RI">Rhode Island</option>
                      <option value="SC">South Carolina</option>
                      <option value="SD">South Dakota</option>
                      <option value="TN">Tennessee</option>
                      <option value="TX">Texas</option>
                      <option value="UT">Utah</option>
                      <option value="VT">Vermont</option>
                      <option value="VA">Virginia</option>
                      <option value="WA">Washington</option>
                      <option value="WV">West Virginia</option>
                      <option value="WI">Wisconsin</option>
                      <option value="WY">Wyoming</option>
                    </select>
                    <FontAwesomeIcon icon={faChevronDown} />
                    <br />
                    <span className="invalid-text">
                      Oops! Please provide your state
                    </span>
                  </fieldset>
                  <fieldset>
                    <label htmlFor="zip">ZIP Code</label>
                    <input
                      required
                      aria-required="true"
                      type="text"
                      id="zip"
                      name="zip"
                      defaultValue=""
                      pattern="[0-9]{5}"
                      maxLength="5"
                      title="ZIP"
                      className="zip"
                    />
                    <span className="invalid-text">
                      Oops! Please provide your zip code
                    </span>
                  </fieldset>
                  <fieldset>
                    <label htmlFor="email">Email Address</label>
                    <input
                      required
                      aria-required="true"
                      type="text"
                      id="email"
                      name="email"
                      defaultValue=""
                      pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,100}$"
                      title="Email "
                      className="validate-email required"
                    />
                    <span className="invalid-text">
                      Oops! That email address doesn't look quite right
                    </span>
                  </fieldset>
                </>
              )}
            </div>
          </div>
          {(formType.includes("hcp") || formType.includes("patient")) && (
            <>
              {formType.includes("patient") ? (
                // BRAVING NEUROBLASTOMA TOOLKIT SECTION
                <div className={"row row--inner mt-2 md_mt-2 lg_mt-2"}>
                  <div className="columns">
                    <h3 className="h4 tc-viridian jost-med">
                      Braving Neuroblastoma Toolkit
                    </h3>
                    <p>
                      This toolkit is a terrific resource for patients and their
                      families. The materials are written in a simple way to
                      help explain neuroblastoma, and what patients can expect
                      on their treatment journey.
                      <br />
                      <em className="tc-barney">
                        Quantities are limited to maximum 1 kit per family.
                      </em>
                    </p>
                  </div>
                </div>
              ) : (
                <div className={"row row--inner mt-2 md_mt-2 lg_mt-2"}>
                  <div className="columns">
                    <h3 className="h4 tc-viridian jost-med">
                      Braving Neuroblastoma Toolkit
                    </h3>
                    <p>
                      This toolkit is a terrific resource for patients and their
                      families. The materials are written in a simple way to
                      help explain neuroblastoma, and what patients can expect
                      on their treatment journey.
                      <br />
                      <em className="tc-barney">
                        Orders are limited to 5 kits and 15 individual books.
                      </em>
                    </p>
                  </div>
                </div>
              )}
              <div className={"row row--inner outline"}>
                <div className="columns small-12 mt-1 md_mt-1 lg_mt-1 mb-1">
                  <p>In this kit you will find:</p>
                  {formType.includes("patient") && (
                    <p>
                      Should an item not be available in your selected language,
                      an English version will be sent out in its place.
                    </p>
                  )}
                </div>
                <div className="columns text-center large-shrink small-12 hide-for-large">
                  <img
                    src={thumbStarterKit}
                    style={{ maxWidth: `230px` }}
                  ></img>
                </div>
                <div className="columns small-12 medium-8 lg_mt-1 md_mt-1 mt-1">
                  <ul className="ul--dotted form">
                    <li>
                      <strong>Skivolo Plush</strong>
                    </li>
                    <li>
                      <strong>4 books</strong>
                      <ul>
                        <li>
                          <strong>The Big Adventures of Little Skivolo</strong>
                          <p className="lg_mt-1 md_mt-1 mt-1">
                            This book is for patients who have been newly
                            diagnosed with neuroblastoma, and their families. It
                            helps explain neuroblastoma in a way that's easy for
                            children to follow—so that they, and their siblings
                            and loved ones, are better able to understand the
                            treatment journey.
                          </p>
                          <p>
                            <strong>
                              The Next Big Adventure of Little Skivolo
                            </strong>
                          </p>
                          <p>
                            This book helps to explain antibody therapy (a
                            medicine that works inside the body to fight
                            neuroblastoma) in a way that's easy for children to
                            understand.{" "}
                          </p>
                          <p>
                            <strong>Little Skivolo's Big Book of Fun</strong>
                            {formType.includes("patient") && (
                              <>
                                <br />
                              </>
                            )}
                          </p>
                          <p>
                            This is an activity and coloring book to help
                            patients and their families stay engaged during
                            their hospital visits.
                          </p>
                          <p>
                            <strong>Zara Takes Off</strong>
                          </p>
                          <p>
                            This book features Skivolo's friend, Zara, who has
                            finished her neuroblastoma treatment, and who learns
                            about what she should expect after treatment and the
                            kind of follow-up care she'll get. With a special
                            foreword from Leah Still, daughter of former NFL
                            player Devon Still.
                          </p>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <strong>Pack of Skivolo crayons</strong>
                    </li>
                    <li>
                      <strong>Treatment journey calendar and stickers</strong>
                      {formType.includes("patient") && (
                        <>
                          <br />
                        </>
                      )}
                      <ul>
                        <li>
                          This treatment calendar and stickers help patients and
                          their families keep track of the important dates and
                          highlight milestones on their treatment journey.
                        </li>
                      </ul>
                    </li>
                    <li>
                      <strong>Adult Skivolo mask and Child Skivolo mask</strong>
                    </li>
                    <li>
                      <strong>Website companion piece</strong>
                      {formType.includes("patient") && (
                        <>
                          <br />
                        </>
                      )}
                      <ul>
                        <li>
                          A guide to the Neuroblastoma-info.com website—where
                          families can find helpful information, resources, and
                          support at any point in their journey: whether newly
                          diagnosed, in treatment, or after treatment.
                        </li>
                      </ul>
                    </li>
                  </ul>
                  {formType.includes("patient") ? (
                    <>
                      <fieldset className="">
                        <input
                          type="radio"
                          id="starter-kit-eng"
                          name="starter-kit-eng"
                          value="1"
                          className=""
                        />
                        <label htmlFor="starter-kit-eng" className="">
                          <strong>Add this to my order</strong>
                        </label>
                      </fieldset>
                      <p className="tc-barney lg_my-1 md_my-1 my-1 ml-1 h6--sml">
                        This kit is only available in English.
                      </p>
                    </>
                  ) : (
                    <>
                      <fieldset>
                        <label
                          htmlFor="starter-kit-quantity"
                          className="horizontal"
                        >
                          Kit Quantity
                        </label>
                        <select
                          className="small"
                          name="starter-kit-quantity"
                          id="starter-kit-quantity"
                        >
                          {max2.map(i => (
                            <option value={i}>{i}</option>
                          ))}
                        </select>
                        <FontAwesomeIcon icon={faChevronDown} />
                      </fieldset>
                      <p className="tc-barney lg_my-1 md_my-1 my-1 h6--sml">
                        This kit is only available in English.
                      </p>
                    </>
                  )}
                </div>
                <div className="columns medium-4 show-for-large">
                  <img src={thumbStarterKit} className="md_mt-2 lg_mt-2"></img>
                </div>
              </div>

              {/* COPING WITH CHiLDHOOD CANCER SECTION */}
              <div className="row row--inner mt-2 md_mt-2 lg_mt-2">
                <div className="columns">
                  <h3 className="h4 tc-viridian jost-med">
                    Coping with Childhood Cancer Kit
                  </h3>
                  <p>
                    Mental and emotional health are very important during the
                    childhood cancer journey. This kit provides education to
                    patients and families on how to manage their stress and
                    anxiety during their treatment journey.
                  </p>
                  {formType.includes("patient") ? (
                    <p>
                      <em className="tc-barney">
                        Quantities are limited to maximum 1 kit per family.
                      </em>
                    </p>
                  ) : (
                    <p>
                      <em className="tc-barney">
                        Orders are limited to 5 kits and 15 individual books.
                      </em>
                    </p>
                  )}
                </div>
              </div>
              <div className={"row row--inner outline"}>
                <div className="columns small-8 mt-1 md_mt-1 lg_mt-1">
                  <p>In this kit you will find:</p>
                </div>
                <div className="columns large-shrink text-center small-12 hide-for-large">
                  <img src={thumbCopingKit} style={{ maxWidth: `230px` }}></img>
                </div>
                <div className="columns medium-8 lg_mt-1 md_mt-1 mt-1">
                  <ul className="ul--dotted form">
                    <li>
                      <strong>Coping with Childhood Cancer Guide.</strong> A
                      step-by-step guide to help set a good foundation for you
                      and your child’s mental health throughout your journey.
                      This guide takes you from initial diagnosis to after
                      treatment, with considerations to help you cope with
                      childhood cancer and advocate for resources that may be
                      available to you. Use this guide as you navigate the
                      childhood cancer journey
                    </li>
                    <li>
                      <strong>Marble Maze with Velcro Back.</strong> This
                      circular plush and velcro maze helps your child stay
                      engaged at home or at medical appointments. They can work
                      the marble through the maze on their own for a calming
                      experience, or pair it with the PunkinPitch balls to play
                      with others
                    </li>
                    <li>
                      <strong>
                        Set of 2 PunkinPitch<sup>&reg;</sup> Balls.
                      </strong>{" "}
                      These unique balls can be played with as is or unwound to
                      create new shapes. They are a great way to help with
                      stress, anxiety, and integrated sensory play. They can
                      also be used with the Velcro<sup>&reg;</sup> Maze for a
                      game of catch
                    </li>
                    <li>
                      <strong>Drawstring backpack.</strong> All of the contents
                      are placed in the backpack
                    </li>
                  </ul>
                  {formType.includes("patient") ? (
                    <fieldset className="">
                      <input
                        type="radio"
                        id="coping-kit-eng"
                        name="coping-kit-eng"
                        value="1"
                        className=""
                      />
                      <label htmlFor="coping-kit-eng" className="">
                        <strong>Add this to my order</strong>
                      </label>
                    </fieldset>
                  ) : (
                    <fieldset>
                      <label
                        htmlFor="coping-kit-quantity"
                        className="horizontal"
                      >
                        Kit Quantity
                      </label>
                      <select
                        className="small"
                        name="coping-kit-quantity"
                        id="coping-kit-quantity"
                      >
                        {max2.map(i => (
                          <option value={i}>{i}</option>
                        ))}
                      </select>
                      <FontAwesomeIcon icon={faChevronDown} />
                    </fieldset>
                  )}
                  <p className="tc-barney lg_my-1 md_my-1 my-1 ml-1 h6--sml">
                    This kit is only available in English.
                  </p>
                </div>
                <div className="columns medium-4 show-for-large">
                  <img src={thumbCopingKit}></img>
                </div>
              </div>

              {formType.includes("patient") && (
                <>
                  <div className={"row row--inner mt-2 md_mt-2 lg_mt-2"}>
                    <div className="columns">
                      <h3 className="h4 tc-viridian jost-med">
                        Ala Carte Items
                      </h3>
                      <p>
                        <em className="tc-barney">
                          Quantities are limited to maximum of 1 ala carte item
                          per family.
                        </em>
                      </p>
                    </div>
                  </div>
                  <div className="row row--inner outline lg_mt-1 md_mt-1 mt-1">
                    <div className="columns large-shrink text-center small-12 medium-3 lg_mt-2 md_mt-2 mt-2">
                      <img src={bigAdventures} style={{ maxWidth: `171px` }} />
                    </div>
                    <div className="columns small-12 medium-9 lg_mt-2 md_mt-2 mt-2 lg_mb-2 md_mb-2 mb-2">
                      <p>
                        <strong>The Big Adventures of Little Skivolo</strong>
                      </p>
                      <p>
                        This book is for patients who have been newly diagnosed
                        with neuroblastoma, and their families. It helps explain
                        neuroblastoma in a way that's easy for children to
                        follow—so that they, and their siblings and loved ones,
                        are better able to understand the treatment journey.
                        Skivolo talks about the different tests a child with
                        neuroblastoma is likely to have at the hospital, and
                        some of the people on the healthcare team who will look
                        after them.
                      </p>

                      <div className="row">
                        <div className="columns small-4 medium-4">
                          <fieldset>
                            <p>Language</p>
                            <p style={{ lineHeight: `1.8` }}>
                              <strong>
                                English
                                <br />
                                Español
                                <br />
                                Français
                              </strong>
                            </p>
                          </fieldset>
                        </div>
                        <div className="columns small-4 medium-4">
                          <fieldset>
                            <p>Quantity</p>
                            <select
                              className="small"
                              name="big-adv-eng-quantity"
                              qty-group="big-adv"
                              id="big-adv-eng-quantity"
                              onChange={e => validateQty(e)}
                            >
                              {max3.map(i => (
                                <option value={i}>{i}</option>
                              ))}
                            </select>
                            <FontAwesomeIcon icon={faChevronDown} />
                            <br />
                            <select
                              className="small"
                              name="big-adv-spa-quantity"
                              qty-group="big-adv"
                              id="big-adv-spa-quantity"
                              onChange={e => validateQty(e)}
                            >
                              {max3.map(i => (
                                <option value={i}>{i}</option>
                              ))}
                            </select>
                            <FontAwesomeIcon icon={faChevronDown} />
                            <br />
                            <select
                              className="small"
                              name="big-adv-fra-quantity"
                              qty-group="big-adv" 
                              id="big-adv-fra-quantity"
                              onChange={e => validateQty(e)}
                            >
                              {max3.map(i => (
                                <option value={i}>{i}</option>
                              ))}
                            </select>
                            <FontAwesomeIcon icon={faChevronDown} />
                          </fieldset>
                        </div>

                        <div
                          className="columns small-4 medium-4"
                          style={{ alignSelf: "end" }}
                        >
                          <fieldset>
                            <AddToOrderButton
                              id="ala-carte-big-adv"
                              disabled={bigAdvQty >= 2 || bigAdvQty < 1}
                            />
                          </fieldset>
                        </div>
                      </div>
                      <span className="invalid-text">
                        Oops! You've exceeded the total number of 1 resources
                        allowed
                      </span>
                    </div>
                  </div>

                  <div className="row row--inner outline lg_mt-1 md_mt-1 mt-1">
                    <div className="columns large-shrink text-center small-12 medium-3  lg_mt-2 md_mt-2 mt-2">
                      <img
                        src={nextBigAdventures}
                        style={{ maxWidth: `171px` }}
                      ></img>
                    </div>
                    <div className="columns small-12 medium-9 lg_mt-2 md_mt-2 mt-2 lg_mb-2 md_mb-2 mb-2">
                      <p>
                        <strong>
                          The Next Big Adventure of Little Skivolo
                        </strong>
                      </p>
                      <p>
                        This book helps to explain antibody therapy, a medicine
                        that is given in the hospital and used to help treat
                        neuroblastoma. It follows Skivolo as he begins antibody
                        therapy, and explains how the medicine works inside the
                        body to help fight neuroblastoma.
                      </p>

                      <div className="row">
                        <div className="columns medium-4">
                          <fieldset>
                            <p>Language</p>
                            <p style={{ lineHeight: `1.8` }}>
                              <strong>
                                English
                                <br />
                                Español
                                <br />
                                Français
                              </strong>
                            </p>
                          </fieldset>
                        </div>
                        <div className="columns">
                          <fieldset>
                            <p>Quantity</p>
                            <select
                              className="small"
                              name="next-big-adv-eng-quantity"
                              id="next-big-adv-eng-quantity"
                              qty-group="next-big-adv"
                              onChange={e => validateQty(e)}
                            >
                              {max3.map(i => (
                                <option value={i}>{i}</option>
                              ))}
                            </select>
                            <FontAwesomeIcon icon={faChevronDown} />
                            <br />
                            <select
                              className="small"
                              name="next-big-adv-spa-quantity"
                              id="next-big-adv-spa-quantity"
                              qty-group="next-big-adv"
                              onChange={e => validateQty(e)}
                            >
                              {max3.map(i => (
                                <option value={i}>{i}</option>
                              ))}
                            </select>
                            <FontAwesomeIcon icon={faChevronDown} />
                            <br />
                            <select
                              className="small"
                              name="next-big-adv-fra-quantity"
                              id="next-big-adv-fra-quantity"
                              qty-group="next-big-adv"
                              onChange={e => validateQty(e)}
                            >
                              {max3.map(i => (
                                <option value={i}>{i}</option>
                              ))}
                            </select>
                            <FontAwesomeIcon icon={faChevronDown} />
                          </fieldset>
                        </div>
                        <div
                          className="columns small-4 medium-4"
                          style={{ alignSelf: "end" }}
                        >
                          <fieldset>
                            <AddToOrderButton
                              id="ala-carte-next-big-adv"
                              disabled={nextBigAdvQty >= 2 || nextBigAdvQty < 1}
                            />
                          </fieldset>
                        </div>
                      </div>
                      <span className="invalid-text">
                        Oops! You've exceeded the total number of 1 resources
                        allowed
                      </span>
                    </div>
                  </div>

                  <div className="row row--inner outline lg_mt-1 md_mt-1 mt-1">
                    <div className="columns large-shrink text-center small-12 medium-3 lg_mt-2 md_mt-2 mt-2">
                      <img src={zara} style={{ maxWidth: `171px` }}></img>
                    </div>
                    <div className="columns small-12 medium-9 lg_mt-2 md_mt-2 mt-2 lg_mb-2 md_mb-2 mb-2">
                      <p>
                        <strong>Zara Takes Off</strong>
                      </p>
                      <p>
                        This book features Skivolo's friend, Zara, who has
                        finished her neuroblastoma treatment. Zara learns about
                        what she should expect after treatment, and the kind of
                        follow-up care she'll get, while she launches into new
                        adventures.
                      </p>
                      <p>
                        Look for a special foreword from Leah Still, daughter of
                        former NFL player Devon Still. Just like Zara, Leah has
                        finished her neuroblastoma treatment and shares details
                        about her journey.
                      </p>

                      <div className="row">
                        <div className="columns small-4 medium-4">
                          <fieldset>
                            <p>Language</p>
                            <p style={{ lineHeight: `1.8` }}>
                              <strong>
                                English
                                <br />
                                Español
                                <br />
                                Français
                              </strong>
                            </p>
                          </fieldset>
                        </div>
                        <div className="columns">
                          <fieldset>
                            <p>Quantity</p>
                            <select
                              className="small"
                              name="zara-eng-quantity"
                              id="zara-eng-quantity"
                              qty-group="zara"
                              onChange={e => validateQty(e)}
                            >
                              {max3.map(i => (
                                <option value={i}>{i}</option>
                              ))}
                            </select>
                            <FontAwesomeIcon icon={faChevronDown} />
                            <br />
                            <select
                              className="small"
                              name="zara-spa-quantity"
                              id="zara-spa-quantity"
                              qty-group="zara"
                              onChange={e => validateQty(e)}
                            >
                              {max3.map(i => (
                                <option value={i}>{i}</option>
                              ))}
                            </select>
                            <FontAwesomeIcon icon={faChevronDown} />
                            <br />
                            <select
                              className="small"
                              name="zara-fra-quantity"
                              id="zara-fra-quantity"
                              qty-group="zara"
                              onChange={e => validateQty(e)}
                            >
                              {max3.map(i => (
                                <option value={i}>{i}</option>
                              ))}
                            </select>
                            <FontAwesomeIcon icon={faChevronDown} />
                          </fieldset>
                        </div>
                        <div
                          className="columns small-4 medium-4"
                          style={{ alignSelf: "end" }}
                        >
                          <fieldset>
                            <AddToOrderButton
                              id="ala-carte-zara"
                              disabled={zaraQty >= 2 || zaraQty < 1}
                            />
                          </fieldset>
                        </div>
                      </div>
                      <span className="invalid-text">
                        Oops! You've exceeded the total number of 1 resources
                        allowed
                      </span>
                    </div>
                  </div>

                  <div className="row row--inner outline lg_mt-1 md_mt-1 mt-1">
                  <div className="columns large-shrink text-center small-12 medium-3  lg_mt-2 md_mt-2 mt-2">
                    <img
                      src={thumbTreatmentJournal}
                      style={{ maxWidth: `171px` }}
                    ></img>
                  </div>
                  <div className="columns small-12 medium-9 lg_mt-2 md_mt-2 mt-2 lg_mb-2 md_mb-2 mb-2">
                    <p>
                      <strong>
                        Treatment journey calendar (stickers included)
                      </strong>
                    </p>
                    <p>
                      This treatment calendar and stickers help patients and
                      their families keep track of the important dates and
                      highlight milestones on their treatment journey.
                    </p>
                  

                    <div className="row">
                      <div className="columns medium-4">
                        <fieldset>
                          <p>Language</p>
                          <p style={{ lineHeight: `1.8` }}>
                            <strong>
                              English
                              <br />
                              Español
                            </strong>
                          </p>
                        </fieldset>
                      </div>
                      <div className="columns">
                        <fieldset>
                          <p>Quantity</p>
                          <select
                            className="small"
                            name="calendar-eng-quantity"
                            id="calendar-eng-quantity"
                            qty-group="calendar"
                            onChange={validateQty}
                          >
                            {max3.map(i => (
                              <option value={i}>{i}</option>
                            ))}
                          </select>
                          <FontAwesomeIcon icon={faChevronDown} />
                          <br />
                          <select
                            className="small"
                            name="calendar-spa-quantity"
                            id="calendar-spa-quantity"
                            qty-group="calendar"
                            onChange={validateQty}
                          >
                            {max3.map(i => (
                              <option value={i}>{i}</option>
                            ))}
                          </select>
                          <FontAwesomeIcon icon={faChevronDown} />
                        </fieldset>
                      </div>
                      <div
                          className="columns small-4 medium-4"
                          style={{ alignSelf: "end" }}
                        >
                          <fieldset>
                            <AddToOrderButton
                              id="ala-carte-cal"
                              disabled={calQty >= 2 || calQty < 1}
                            />
                          </fieldset>
                        </div>
                    </div>
                    <span className="invalid-text">
                      Oops! You've exceeded the total number of 1 resources
                      allowed
                    </span>
                  </div>
                </div>
                </>
              )}
            </>
          )}

          {
            //HCP
            formType.includes("hcp") && (
              <>
                <div className={"row row--inner mt-2 md_mt-2 lg_mt-2"}>
                  <div className="columns">
                    <h3 className="h4 tc-viridian jost-med">
                      Individual Resources
                    </h3>
                    <p className="tc-viridian">
                      <strong>Skivolo and Zara Books</strong>
                    </p>
                    <p>
                      These books feature the adventures of Skivolo, a young red
                      panda with high-risk neuroblastoma and his friend Zara,
                      who has just finished her neuroblastoma treatment. Please
                      select the resources you would like to order from the
                      following options below:{" "}
                    </p>
                  </div>
                </div>
                <div className="row row--inner outline lg_mt-1 md_mt-1 mt-1">
                  <div className="columns large-shrink text-center small-12 medium-3 lg_mt-2 md_mt-2 mt-2">
                    <img src={bigAdventures} style={{ maxWidth: `171px` }} />
                  </div>
                  <div className="columns small-12 medium-9 lg_mt-2 md_mt-2 mt-2 lg_mb-2 md_mb-2 mb-2">
                    <p>
                      <strong>The Big Adventures of Little Skivolo</strong>
                    </p>
                    <p>
                      This book is for patients who have been newly diagnosed
                      with neuroblastoma, and their families. It helps explain
                      neuroblastoma in a way that's easy for children to
                      follow—so that they, and their siblings and loved ones,
                      are better able to understand the treatment journey.
                      Skivolo talks about the different tests a child with
                      neuroblastoma is likely to have at the hospital, and some
                      of the people on the healthcare team who will look after
                      them.
                    </p>

                    <div className="row">
                      <div className="columns small-6 medium-4">
                        <fieldset>
                          <p>Language</p>
                          <p style={{ lineHeight: `1.8` }}>
                            <strong>
                              English
                              <br />
                              Español
                              <br />
                              Français
                            </strong>
                          </p>
                        </fieldset>
                      </div>
                      <div className="columns small-6 medium-8">
                        <fieldset>
                          <p>Quantity</p>
                          <select
                            className="small"
                            name="big-adv-eng-quantity"
                            qty-group="big-adv"
                            id="big-adv-eng-quantity"
                            onChange={e => validateQty(e)}
                          >
                            {max1.map(i => (
                              <option value={i}>{i}</option>
                            ))}
                          </select>
                          <FontAwesomeIcon icon={faChevronDown} />
                          <br />
                          <select
                            className="small"
                            name="big-adv-spa-quantity"
                            qty-group="big-adv"
                            id="big-adv-spa-quantity"
                            onChange={e => validateQty(e)}
                          >
                            {max1.map(i => (
                              <option value={i}>{i}</option>
                            ))}
                          </select>
                          <FontAwesomeIcon icon={faChevronDown} />
                          <br />
                          <select
                            className="small"
                            name="big-adv-fra-quantity"
                            qty-group="big-adv"
                            id="big-adv-fra-quantity"
                            onChange={e => validateQty(e)}
                          >
                            {max1.map(i => (
                              <option value={i}>{i}</option>
                            ))}
                          </select>
                          <FontAwesomeIcon icon={faChevronDown} />
                        </fieldset>
                      </div>
                    </div>
                    <span className="invalid-text">
                      Oops! You've exceeded the total number of 5 resources
                      allowed
                    </span>
                  </div>
                </div>

                <div className="row row--inner outline lg_mt-1 md_mt-1 mt-1">
                  <div className="columns large-shrink text-center small-12 medium-3  lg_mt-2 md_mt-2 mt-2">
                    <img
                      src={nextBigAdventures}
                      style={{ maxWidth: `171px` }}
                    ></img>
                  </div>
                  <div className="columns small-12 medium-9 lg_mt-2 md_mt-2 mt-2 lg_mb-2 md_mb-2 mb-2">
                    <p>
                      <strong>The Next Big Adventure of Little Skivolo</strong>
                    </p>
                    <p>
                      This book helps to explain antibody therapy, a medicine
                      that is given in the hospital and used to help treat
                      neuroblastoma. It follows Skivolo as he begins antibody
                      therapy, and explains how the medicine works inside the
                      body to help fight neuroblastoma.
                    </p>

                    <div className="row">
                      <div className="columns medium-4">
                        <fieldset>
                          <p>Language</p>
                          <p style={{ lineHeight: `1.8` }}>
                            <strong>
                              English
                              <br />
                              Español
                              <br />
                              Français
                            </strong>
                          </p>
                        </fieldset>
                      </div>
                      <div className="columns">
                        <fieldset>
                          <p>Quantity</p>
                          <select
                            className="small"
                            name="next-big-adv-eng-quantity"
                            id="next-big-adv-eng-quantity"
                            qty-group="next-big-adv"
                            onChange={e => validateQty(e)}
                          >
                            {max1.map(i => (
                              <option value={i}>{i}</option>
                            ))}
                          </select>
                          <FontAwesomeIcon icon={faChevronDown} />
                          <br />
                          <select
                            className="small"
                            name="next-big-adv-spa-quantity"
                            id="next-big-adv-spa-quantity"
                            qty-group="next-big-adv"
                            onChange={e => validateQty(e)}
                          >
                            {max1.map(i => (
                              <option value={i}>{i}</option>
                            ))}
                          </select>
                          <FontAwesomeIcon icon={faChevronDown} />
                          <br />
                          <select
                            className="small"
                            name="next-big-adv-fra-quantity"
                            id="next-big-adv-fra-quantity"
                            qty-group="next-big-adv"
                            onChange={e => validateQty(e)}
                          >
                            {max1.map(i => (
                              <option value={i}>{i}</option>
                            ))}
                          </select>
                          <FontAwesomeIcon icon={faChevronDown} />
                        </fieldset>
                      </div>
                    </div>
                    <span className="invalid-text">
                      Oops! You've exceeded the total number of 5 resources
                      allowed
                    </span>
                  </div>
                </div>

                <div className="row row--inner outline lg_mt-1 md_mt-1 mt-1">
                  <div className="columns large-shrink text-center small-12 medium-3 lg_mt-2 md_mt-2 mt-2">
                    <img src={zara} style={{ maxWidth: `171px` }}></img>
                  </div>
                  <div className="columns small-12 medium-9 lg_mt-2 md_mt-2 mt-2 lg_mb-2 md_mb-2 mb-2">
                    <p>
                      <strong>Zara Takes Off</strong>
                    </p>
                    <p>
                      This book features Skivolo's friend, Zara, who has
                      finished her neuroblastoma treatment. Zara learns about
                      what she should expect after treatment, and the kind of
                      follow-up care she'll get, while she launches into new
                      adventures.
                    </p>
                    <p>
                      Look for a special foreword from Leah Still, daughter of
                      former NFL player Devon Still. Just like Zara, Leah has
                      finished her neuroblastoma treatment and shares details
                      about her journey.
                    </p>

                    <div className="row">
                      <div className="columns medium-4">
                        <fieldset>
                          <p>Language</p>
                          <p style={{ lineHeight: `1.8` }}>
                            <strong>
                              English
                              <br />
                              Español
                              <br />
                              Français
                            </strong>
                          </p>
                        </fieldset>
                      </div>
                      <div className="columns">
                        <fieldset>
                          <p>Quantity</p>
                          <select
                            className="small"
                            name="zara-eng-quantity"
                            id="zara-eng-quantity"
                            qty-group="zara"
                            onChange={e => validateQty(e)}
                          >
                            {max1.map(i => (
                              <option value={i}>{i}</option>
                            ))}
                          </select>
                          <FontAwesomeIcon icon={faChevronDown} />
                          <br />
                          <select
                            className="small"
                            name="zara-spa-quantity"
                            id="zara-spa-quantity"
                            qty-group="zara"
                            onChange={e => validateQty(e)}
                          >
                            {max1.map(i => (
                              <option value={i}>{i}</option>
                            ))}
                          </select>
                          <FontAwesomeIcon icon={faChevronDown} />
                          <br />
                          <select
                            className="small"
                            name="zara-fra-quantity"
                            id="zara-fra-quantity"
                            qty-group="zara"
                            onChange={e => validateQty(e)}
                          >
                            {max1.map(i => (
                              <option value={i}>{i}</option>
                            ))}
                          </select>
                          <FontAwesomeIcon icon={faChevronDown} />
                        </fieldset>
                      </div>
                    </div>
                    <span className="invalid-text">
                      Oops! You've exceeded the total number of 5 resources
                      allowed
                    </span>
                  </div>
                </div>

                <div className="row row--inner outline lg_mt-1 md_mt-1 mt-1">
                  <div className="columns large-shrink text-center small-12 medium-3  lg_mt-2 md_mt-2 mt-2">
                    <img src={bookOfFun} style={{ maxWidth: `171px` }}></img>
                  </div>
                  <div className="columns small-12 medium-9 lg_mt-2 md_mt-2 mt-2 lg_mb-2 md_mb-2 mb-2">
                    <p>
                      <strong>Little Skivolo's Big Book of Fun</strong> (crayons
                      included)
                    </p>
                    <p>
                      This is an activity and coloring book for patients and
                      their families. The coloring activities, games, crafts,
                      and puzzles in this book can keep your child and their
                      siblings engaged during hospital visits.
                    </p>

                    <div className="row">
                      <div className="columns medium-4">
                        <fieldset>
                          <p>Language</p>
                          <p style={{ lineHeight: `1.8` }}>
                            <strong>
                              English
                              <br />
                              Français
                            </strong>
                          </p>
                        </fieldset>
                      </div>
                      <div className="columns">
                        <fieldset>
                          <p>Quantity</p>
                          <select
                            className="small"
                            name="skivolo-eng-quantity"
                            id="skivolo-eng-quantity"
                            qty-group="skivolo"
                            onChange={e => validateQty(e)}
                          >
                            {max1.map(i => (
                              <option value={i}>{i}</option>
                            ))}
                          </select>
                          <FontAwesomeIcon icon={faChevronDown} />
                          <br />
                          <select
                            className="small"
                            name="skivolo-fra-quantity"
                            id="skivolo-fra-quantity"
                            qty-group="skivolo"
                            onChange={e => validateQty(e)}
                          >
                            {max1.map(i => (
                              <option value={i}>{i}</option>
                            ))}
                          </select>
                          <FontAwesomeIcon icon={faChevronDown} />
                        </fieldset>
                      </div>
                    </div>
                    <span className="invalid-text">
                      Oops! You've exceeded the total number of 5 resources
                      allowed
                    </span>
                  </div>
                </div>

                <div className="row row--inner outline lg_mt-1 md_mt-1 mt-1">
                  <div className="columns large-shrink text-center small-12 medium-3  lg_mt-2 md_mt-2 mt-2">
                    <img
                      src={thumbTreatmentJournal}
                      style={{ maxWidth: `171px` }}
                    ></img>
                  </div>
                  <div className="columns small-12 medium-9 lg_mt-2 md_mt-2 mt-2 lg_mb-2 md_mb-2 mb-2">
                    <p>
                      <strong>
                        Treatment journey calendar (stickers included)
                      </strong>
                    </p>
                    <p>
                      This treatment calendar and stickers help patients and
                      their families keep track of the important dates and
                      highlight milestones on their treatment journey.
                    </p>
                    <p className="tc-barney">
                      Please select your language preferences and quantity:{" "}
                      <br />
                      (5 calendars maximum total)
                    </p>

                    <div className="row">
                      <div className="columns medium-4">
                        <fieldset>
                          <p>Language</p>
                          <p style={{ lineHeight: `1.8` }}>
                            <strong>
                              English
                              <br />
                              Español
                            </strong>
                          </p>
                        </fieldset>
                      </div>
                      <div className="columns">
                        <fieldset>
                          <p>Quantity</p>
                          <select
                            className="small"
                            name="calendar-eng-quantity"
                            id="calendar-eng-quantity"
                            qty-group="calendar"
                            onChange={validateQty}
                          >
                            {max2.map(i => (
                              <option value={i}>{i}</option>
                            ))}
                          </select>
                          <FontAwesomeIcon icon={faChevronDown} />
                          <br />
                          <select
                            className="small"
                            name="calendar-spa-quantity"
                            id="calendar-spa-quantity"
                            qty-group="calendar"
                            onChange={validateQty}
                          >
                            {max2.map(i => (
                              <option value={i}>{i}</option>
                            ))}
                          </select>
                          <FontAwesomeIcon icon={faChevronDown} />
                        </fieldset>
                      </div>
                    </div>
                    <span className="invalid-text">
                      Oops! You've exceeded the total number of 5 resources
                      allowed
                    </span>
                  </div>
                </div>

                <div className="row row--inner outline lg_mt-1 md_mt-1 mt-1">
                  <div className="columns large-shrink text-center small-12 medium-3  lg_mt-2 md_mt-2 mt-2">
                    <img
                      src={thumbStickerRefill}
                      style={{ maxWidth: `171px` }}
                    ></img>
                  </div>
                  <div className="columns small-12 medium-9 lg_mt-2 md_mt-2 mt-2 lg_mb-2 md_mb-2 mb-2">
                    <p>
                      <strong>Sticker refill pack</strong>
                    </p>
                    <p>
                      Refill packs include stickers of Skivolo and his friends,
                      who are the focus of our educational book series, holiday
                      stickers, and stickers for other activities.
                    </p>
                    <p className="tc-barney">
                      This item is only available in English.
                    </p>

                    <fieldset>
                      <label htmlFor="sticker-quantity" className="horizontal">
                        Quantity
                      </label>
                      <select
                        className="small"
                        name="sticker-quantity"
                        id="sticker-quantity"
                      >
                        {max1.map(i => (
                          <option value={i}>{i}</option>
                        ))}
                      </select>
                      <FontAwesomeIcon icon={faChevronDown} />
                    </fieldset>
                  </div>
                </div>

                <div className="row row--inner outline lg_mt-1 md_mt-1 mt-1">
                  <div className="columns large-shrink text-center small-12 medium-3  lg_mt-2 md_mt-2 mt-2">
                    <img
                      src={thumbResource}
                      style={{ maxWidth: `171px` }}
                    ></img>
                  </div>
                  <div className="columns small-12 medium-9 lg_mt-2 md_mt-2 mt-2 lg_mb-2 md_mb-2 mb-2">
                    <p>
                      <strong>Coping with Childhood Cancer Guide</strong>
                    </p>
                    <p>
                      A step-by-step guide to help set a good foundation for you
                      and your child's mental health throughout your journey.
                      This guide takes you from initial diagnosis to after
                      treatment, with considerations to help you cope with
                      childhood cancer and advocate for resources that may be
                      available to you. Use this guide as you navigate the
                      childhood cancer journey.
                    </p>
                    <p className="tc-barney">
                      This item is only available in English.
                    </p>

                    <fieldset>
                      <label htmlFor="coping-quantity" className="horizontal">
                        Quantity
                      </label>
                      <select
                        className="small"
                        name="coping-quantity"
                        id="coping-quantity"
                      >
                        {max1.map(i => (
                          <option value={i}>{i}</option>
                        ))}
                      </select>
                      <FontAwesomeIcon icon={faChevronDown} />
                    </fieldset>
                  </div>
                </div>

                <div className="row row--inner outline lg_mt-1 md_mt-1 mt-1">
                  <div className="columns large-shrink text-center small-12 medium-3 lg_mt-2 md_mt-2 mt-2">
                    <img
                      src={thumbCompanionPiece}
                      style={{ maxWidth: `171px` }}
                    ></img>
                  </div>
                  <div className="columns small-12 medium-9 lg_mt-2 md_mt-2 mt-2 lg_mb-2 md_mb-2 mb-2">
                    <p>
                      <strong>Website companion piece</strong>
                    </p>
                    <p>
                      This piece is used to drive families to the
                      Neuroblastoma-info.com website, where they can find
                      helpful information, resources, and support at any point
                      in their journey; whether newly diagnosed, in treatment,
                      or after treatment.{" "}
                    </p>
                    <p className="tc-barney">
                      This item is only available in English.
                    </p>

                    <fieldset>
                      <label
                        htmlFor="website-companion-quantity"
                        className="horizontal"
                      >
                        Quantity
                      </label>
                      <select
                        className="small"
                        name="website-companion-quantity"
                        id="website-companion-quantity"
                      >
                        {max1.map(i => (
                          <option value={i}>{i}</option>
                        ))}
                      </select>
                      <FontAwesomeIcon icon={faChevronDown} />
                    </fieldset>
                  </div>
                </div>
              </>
            )
          }

          {(formType.includes("hcp") || formType.includes("patient")) && (
            <>
              <div className={"row row--inner mt-3 lg_mt-3 md_mt-3"}>
                <div className="columns">
                  <p>
                    <strong>United Therapeutics respects your privacy.</strong>
                  </p>
                  <p>
                    By clicking SUBMIT, I agree to have my personal information
                    used to provide me with information and resources regarding
                    products, programs, and services related to my condition,
                    including treatment information. Information sent by United
                    Therapeutics does not take the place of talking with your
                    healthcare provider about your treatment or condition.
                    United Therapeutics, or third parties working on its behalf,
                    will not sell your information or use it for unrelated
                    purposes. If in the future you no longer want to receive
                    these materials or participate in these programs, you can
                    unsubscribe at any time by clicking the link at the bottom
                    of the page. For more information, please review our{" "}
                    <Link to="/privacy/">Privacy Policy.</Link>
                  </p>
                  <fieldset>
                    <input type="checkbox" id="email-opt-in" name="email-opt-in" />
                    <label htmlFor="email-opt-in">I’d like to receive emails to stay informed about valuable educational resources.</label>
                    <br />
                  </fieldset>
                  <fieldset>
                    <input required type="checkbox" id="iam18" name="iam18" />
                    <label htmlFor="iam18">I am at least 18 years old</label>
                    <br />
                  </fieldset>
                  <br />
                  <fieldset>
                    <input
                      type="hidden"
                      value={formType}
                      name="request-type"
                      id="request-type"
                    />
                    <button className="btn--secondary">
                      Submit My Request Form
                    </button>
                  </fieldset>
                </div>
              </div>
            </>
          )}
        </form>
      </Section>
    </Layout>
  )
}

export default RequestResources
